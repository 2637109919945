export const ORIGINS = {
  BIO: "Bio",
  LR: "Label Rouge",
  AOC: "AOC",
  AOP: "AOP",
  IGP: "IGP",
  RUP: "Régions Ultrapériphériques",
  FM: "Fait maison",
  LOC: "Local",
  MM: "Menu malin",
  MSC: "MSC",
  PDU: "Pêche durable",
  POF: "Porc français",
  VLF: "Volaille française",
  LAF: "Lapin de france",
  OEF: "Oeufs de france",
  VVF: "Viande de veau française",
  VCF: "Viande chevaline française",
  VBF: "Viande bovine française",
  VOF: "Viande ovine française",
  VAF: "Viande d'agneau française",
  CHF: "Viande de chèvre française",
  CVF: "Viande de chevreau française",
  FGF: "Foie gras de france",
  VEG: "Option végé",
  SPI: "Spicy",
};

export const PRODUCT_TYPES = {
  STARTER: "ENT",
  MAIN: "PLA",
  DESSERT: "DES",
  DRINK: "BOI",
  SIDE: "ACC",
  // FORMULA: "FOR",
  DAIRY: "LAI",
  CHEESE: "FRO",
  OTHER: "AUT",
};

export const PRODUCT_TYPES_NAMES = {
  [PRODUCT_TYPES.STARTER]: "Entrée",
  [PRODUCT_TYPES.MAIN]: "Plat",
  [PRODUCT_TYPES.DESSERT]: "Dessert",
  [PRODUCT_TYPES.CHEESE]: "Fromage",
  [PRODUCT_TYPES.DAIRY]: "Laitage",
  [PRODUCT_TYPES.DRINK]: "Boisson",
  [PRODUCT_TYPES.SIDE]: "Accompagnement",
  [PRODUCT_TYPES.OTHER]: "Autre",
  // [PRODUCT_TYPES.FORMULA]: "Spécial : prix formule"
};
